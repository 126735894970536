@import './variables';

@mixin dark() {
  :root {
    &.dark {
      @content;
    }
    @media (prefers-color-scheme: dark) {
      @content;
    }
  }
}

@mixin light() {
  :root {
    &.light {
      @content;
    }
    @media (prefers-color-scheme: light) {
      @content;
    }
  }
}

@mixin addAllGradientsAsFill($selectorToFill) {
  @for $i from 1 through length($gradients) {
    $name: 'grad' + $i;
    &.#{$name} {
      @include addGradientFill($i, $selectorToFill);
    }
    &:not(.static):hover.#{$name} #{$selectorToFill} {
      --colorStop: var(--colorStop2);
    }
  }
}

@mixin addGradientFill($gradNo, $selectorToFill: '') {
  $tuple: nth($gradients, $gradNo);
  --colorStop: #{nth($tuple, 1)};
  --colorStop2: #{nth($tuple, 2)};
  &:not(:empty) #{$selectorToFill} {
    --colorStop: #{nth($tuple, 1)};
    --colorStop2: #{nth($tuple, 2)};
    background: linear-gradient(134.85deg, var(--colorStop) 1.75%, var(--colorStop2) 100%), var(--colorStop);
  }
}

@mixin addPopout() {
  box-shadow: 5px 5px 10px -6px rgb(255 255 255 / 10%) inset, 2px 3px 5px rgb(0 0 0 / 20%);
}
