@import './variables';

// Apply gradients
// When gradX class is set on a parent element
@for $i from 1 through length($gradients) {
  $tuple: nth($gradients, $i);
  .#{'grad' + $i} .flx-icon.shine {
    --stop-color: #{nth($tuple, 1)};
    --stop-color2: #{nth($tuple, 2)};
  }
}

// When gradX class is set directly on icon
@for $i from 1 through length($gradients) {
  $tuple: nth($gradients, $i);
  .flx-icon.shine.#{'grad' + $i} {
    --stop-color: #{nth($tuple, 1)};
    --stop-color2: #{nth($tuple, 2)};
  }
}
