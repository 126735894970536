// prettier-ignore
:root {
  --mat-badge-background-color: var(--panelHead);
  --mat-badge-container-overlap-offset: -6px 4px;
  --mat-badge-container-size: 24px;
  --mat-checkbox-label-text-color: var(--bodyText);
  --mat-divider-color: var(--mutedText);
  --mat-expansion-container-background-color: var(--panelBack);
  --mat-expansion-container-text-color: var(--bodyText);
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-description-color: var(--bodyText);
  --mat-expansion-header-disabled-state-text-color: var(--text);
  --mat-expansion-header-expanded-state-height: 48px;
  --mat-expansion-header-indicator-color: var(--bodyText);
  --mat-expansion-header-text-color: var(--text);
  --mat-expansion-header-text-size: 1.2rem;
  --mat-form-field-container-height: 46px;
  --mat-form-field-container-text-line-height: 28px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-filled-with-label-container-padding-bottom: 0px;
  --mat-form-field-filled-with-label-container-padding-top: 7px;
  --mat-icon-color: var(--mutedText);
  --mat-optgroup-label-text-color: var(--text);
  --mat-option-label-text-color: var(--bodyText);
  --mat-option-selected-state-label-text-color: var(--text);
  --mat-paginator-container-background-color: var(--panelBack);
  --mat-paginator-container-text-color: var(--bodyText);
  --mat-paginator-disabled-icon-color: var(--mutedText);
  --mat-select-enabled-arrow-color: var(--bodyText);
  --mat-select-enabled-trigger-text-color: var(--bodyText);
  --mat-select-focused-arrow-color: var(--text);
  --mat-select-panel-background-color: var(--panelBack);
  --mat-select-trigger-text-line-height: 42px;
  --mat-snack-bar-button-color: #abc7ff;
  --mdc-checkbox-disabled-selected-icon-color: var(--mutedText);
  --mdc-checkbox-disabled-unselected-icon-color: var(--mutedText);
  --mdc-checkbox-selected-checkmark-color: var(--primaryText);
  --mdc-checkbox-selected-focus-icon-color: var(--primary);
  --mdc-checkbox-selected-hover-icon-color: var(--primary);
  --mdc-checkbox-selected-icon-color: var(--primary);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary);
  --mdc-checkbox-unselected-focus-icon-color: var(--primaryText);
  --mdc-checkbox-unselected-hover-icon-color: var(--primaryText);
  --mdc-checkbox-unselected-icon-color: var(--text);
  --mdc-checkbox-unselected-pressed-icon-color: var(--text);
  --mdc-dialog-container-color: var(--panelBack);
  --mdc-dialog-container-shape: var(--borderRadius);
  --mdc-dialog-subhead-color: var(--text);
  --mdc-dialog-supporting-text-color: var(--text);
  --mdc-elevated-card-container-color: var(--panelBack);
  --mdc-elevated-card-container-shape: var(--borderRadius);
  --mdc-fab-small-container-shape: 50%;
  --mdc-filled-text-field-active-indicator-color: var(--mutedText);
  --mdc-filled-text-field-active-indicator-height: 2px;
  --mdc-filled-text-field-caret-color: var(--text);
  --mdc-filled-text-field-container-color: var(--light);
  --mdc-filled-text-field-container-shape: var(--borderRadius);
  --mdc-filled-text-field-focus-active-indicator-color: var(--text);
  --mdc-filled-text-field-focus-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-indicator-color: var(--mutedText);
  --mdc-filled-text-field-focus-label-text-color: var(--text);
  --mdc-filled-text-field-input-text-color: var(--bodyText);
  --mdc-form-field-label-text-color: var(--bodyText);
  --mdc-icon-button-icon-size: 28px;
  --mdc-list-list-item-hover-label-text-color: var(--text);
  --mdc-list-list-item-label-text-color: var(--bodyText);
  --mdc-list-list-item-supporting-text-color: var(--mutedText);
  --mdc-list-list-item-trailing-supporting-text-color: var(--mutedText);
  --mdc-protected-button-container-color: var(--panelHead);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 3px -2px rgba(0, 0, 0, .2), 0px 3px 4px 0px rgba(0, 0, 0, .14), 0px 1px 8px 0px rgba(0, 0, 0, .12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, .2), 0px 0px 0px 0px rgba(0, 0, 0, .14), 0px 0px 0px 0px rgba(0, 0, 0, .12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 1px 3px 0px rgba(0, 0, 0, .12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 1px 3px 0px rgba(0, 0, 0, .12);
  --mdc-protected-button-label-text-color: var(--text);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, .2), 0px 1px 1px 0px rgba(0, 0, 0, .14), 0px 1px 3px 0px rgba(0, 0, 0, .12);
  --mdc-shape-medium: var(--borderRadius);
  --mdc-snackbar-container-color: var(--text);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-supporting-text-color: var(--background);
  --mdc-text-button-label-text-color: var(--bodyText);
  --mdc-theme-text-primary-on-background: var(--text);
}
lib-select-customer {
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh !important;
  &::before {
    background: transparent !important;
  }
}

.loader {
  color: var(--subtle);
}

// Material overrides
.mdc-dialog__container {
  background: var(--background);
  color: var(--text);
  box-shadow:
    0 11px 15px -7px #0003,
    0 24px 38px 3px #00000024,
    0 9px 46px 8px #0000001f;
}

.mat-select-panel {
  background: var(--panelBack);
}

body {
  .mat-ripple-element {
    // The new m3 ripple is buggy and creates ripples over a much larger space than the actual element
    width: 100% !important;
    height: 100% !important;
  }
  .mat-mdc-tab-link-container,
  .mat-mdc-tab-label-container {
    border: 0;
    .mdc-tab {
      position: relative;
      height: 56px;
    }
  }
  .mat-mdc-form-field {
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    --mdc-theme-primary: var(--disableGradientColor);
    &.mat-focused {
      // border-color: var(--subtle);
    }
    &:active,
    &:focus {
      outline: 0;
    }
    .mdc-floating-label {
      font-size: 1rem;
    }
    .mdc-text-field:not(.mdc-text-field--disabled) {
      &:focus-within {
        background-color: var(--background);
      }
      .mdc-floating-label {
        color: var(--text);
        font-size: 1rem;
      }
    }
    .mat-mdc-input-element::placeholder,
    .mat-mdc-input-element::ms-input-placeholder {
      color: currentColor;
      background: red;
    }
    .mat-mdc-form-field-infix {
      width: 100%;
      border-top: 0;
      display: flex;
    }
    .mat-mdc-form-field-prefix,
    .mat-mdc-form-field-suffix {
      // bottom: -16px;
      color: var(--mutedText);
    }
    &.mat-focused {
      .mat-mdc-form-field-label {
        color: var(--primary);
        opacity: 0.64;
      }
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
  input,
  .mat-input-element {
    caret-color: var(--text);
    height: 48px;
    padding: 15px 5px 0 5px;
    box-sizing: inherit;
    &::placeholder {
      color: var(--mutedText);
    }
  }
  .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
  .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after,
  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
  .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
    color: var(--text);
  }
}

lib-language-selector .mat-form-field .mat-form-field-flex {
  background-color: transparent;
  box-shadow: none;
}
.mat-mdc-list-item .mat-mdc-select {
  place-items: center;
}

.mat-mdc-select-arrow-wrapper {
  .mat-mdc-select-arrow {
    width: 17px;
  }
}
.mat-option {
  color: var(--mutedText);
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled),
.mat-option.mat-active {
  color: var(--text);
}

.mat-mdc-list-base {
  .mat-mdc-list-item {
    color: var(--text);
    font-size: 1rem;
  }
  .mat-mdc-list-item,
  .mat-mdc-list-option {
    height: 3rem;
  }
  .mat-subheader {
    color: var(--mutedText);
  }
}
body {
  .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
  .mat-button-toggle-group-appearance-standard {
    --toggleBorder: var(--greyHover);
    --mat-standard-button-toggle-shape: calc(var(--borderRadius) / 1.5);
    border: none;
    outline-color: var(--toggleBorder);
    outline-offset: -1px;
    outline-style: solid;
    outline-width: 1px;
    .mat-button-toggle {
      background-color: transparent;
      color: var(--mutedText);
      flex: 1;
      border-color: var(--toggleBorder) !important;
      border-style: solid;
      border-width: 1px 1px 0 1px;
      &.mat-button-toggle-checked {
        color: var(--text);
        background: var(--selectedBackground);
      }
      + .mat-button-toggle {
        border-width: 1px 1px 0 0;
        border-color: var(--toggleBorder) !important;
      }
      .mat-button-toggle-label-content {
        line-height: 32px;
      }
    }
  }
}

.mat-icon {
  stroke: currentColor;
}

lib-map > svg #neighborhood path {
  // fill-opacity: 0.1 !important;
  stroke-opacity: 0.8;
}

.mat-mdc-tab,
.mat-mdc-tab-link {
  &:not(.mat-mdc-tab-disabled) {
    .mdc-tab-indicator__content--underline {
      --mdc-tab-indicator-active-indicator-color: var(--text);
    }
  }
  .mdc-tab__text-label,
  .mdc-tab__text-label {
    color: inherit;
    font-weight: 700;
    opacity: 0.8;
  }
  .active,
  &.mdc-tab--active {
    opacity: 1;
    font-size: 1.2rem;
    color: var(--bodyText);
    .mdc-tab__text-label {
      color: inherit !important;
    }
  }
}
.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--bodyText);
}
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--text);
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: var(--bodyText);
  --mat-tab-header-inactive-label-text-color: var(--mutedText);
  --mat-tab-header-active-label-text-color: var(--text);
  --mat-tab-header-active-ripple-color: var(--text);
  --mat-tab-header-inactive-ripple-color: var(--text);
  --mat-tab-header-inactive-focus-label-text-color: var(--mutedText);
  --mat-tab-header-inactive-hover-label-text-color: var(--mutedText);
  --mat-tab-header-active-focus-label-text-color: var(--text);
  --mat-tab-header-active-hover-label-text-color: var(--text);
  --mat-tab-header-active-focus-indicator-color: var(--text);
  --mat-tab-header-active-hover-indicator-color: var(--text);
  .active .mat-mdc-tab-link .mdc-tab__text-label {
    color: var(--mdc-tab-indicator-active-indicator-color);
  }
}
.mdc-tab__ripple::before,
.mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-link .mat-ripple-element {
  background-color: var(--background);
}
.mat-mdc-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: inherit;
}

.mat-mdc-checkbox {
  line-height: 48px;
}
.mat-expansion-panel-header {
  height: var(--mat-expansion-header-collapsed-state-height);
}
body .page .mat-accordion .mat-expansion-panel {
  --mat-expansion-container-shape: var(--borderRadius);
  margin: 0.6rem 0;
  // &:not(.mat-expanded):has(+ .mat-expanded) {
  border-bottom-left-radius: var(--mat-expansion-container-shape);
  border-bottom-right-radius: var(--mat-expansion-container-shape);
  // }
  // &.mat-expanded + .mat-expansion-panel {
  border-top-left-radius: var(--mat-expansion-container-shape);
  border-top-right-radius: var(--mat-expansion-container-shape);
  // }
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-unselected-icon-color: var(--mutedText);
}
