@keyframes notReady {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 200% 0%;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
body flx-root .load-anim {
  --top: 20%;
  --bottom: 80%;
  background: linear-gradient(
      0,
      var(--background),
      transparent var(--top),
      transparent var(--bottom),
      var(--background)
    ),
    linear-gradient(-45deg, var(--background) 25%, var(--muted) 50%, var(--background) 75%);
  background-size: 200% 100%;
  animation: notReady 2s linear infinite;
  > * {
    opacity: 0.2;
  }
  &:after,
  &:before {
    content: '';
    background: none !important;
  }
}

.map.load-anim {
  background-blend-mode: lighten;
  mix-blend-mode: lighten;
  padding: 3px 0;
}

.map > img {
  animation: fadeIn 0.1s ease-in-out;
}
