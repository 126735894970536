@use '@angular/material' as mat;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.booking-card {
  $iconWidth: 2.5rem;

  &:not(:empty),
  &:has(.load-anim) {
    article {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 8px;
      border-radius: var(--borderRadius);
      @include mat.elevation(5);
      &:not(.load-anim) {
        background: var(--panelBack);
      }
      &:has(> footer) {
        padding-bottom: 0;
      }
      @media screen and (min-width: 600px) {
        padding: 1.2rem;
      }
      &.load-anim {
        --top: 2%;
        --bottom: 98%;
        display: grid;
        grid-template:
          'header image' auto
          'map    map' auto
          'footer footer' auto
          / 1fr auto;

        .placeholder {
          letter-spacing: -3px;
        }
        header {
          grid-area: header;
          display: grid;
          grid-template:
            'icon header' 1.4rem
            'icon subhead' auto
            / $iconWidth 1fr;
          place-items: flex-start;
          lib-loader {
            grid-area: icon;
            margin-left: -1rem;
            .loader {
              color: var(--text);
            }
          }
          h4 {
            grid-area: header;
          }
          .subheader {
            grid-area: subhead;
          }
        }
        aside {
          grid-area: image;
          height: clamp(7rem, 20vw, 9rem);
          width: clamp(8rem, 20vw, 12rem);
          border-radius: calc(var(--borderRadius) / 2);
          box-shadow:
            0 3px 1px -2px #0003,
            0 2px 2px #00000024,
            0 1px 5px #0000001f;
          background: var(--muted);
        }
        .buttons {
          grid-area: image;
          display: flex;
        }
        .map {
          grid-area: map;
          min-height: 8rem;
        }
        footer {
          grid-area: footer;
          min-height: 3rem;
        }
        .spin {
          animation: spin 1s linear infinite;
        }
      }
    }
    .flx-icon {
      width: $iconWidth;
      height: $iconWidth;
      margin-left: -0.3rem;
      margin-right: 0.3rem;
      top: 0;
    }
    header {
      display: flex;
      place-items: center;
      h4 {
        flex: 1;
        font-weight: 400;
        color: var(--text);
        font-size: 1.2rem;
        margin: 0;
      }
    }
    aside {
      picture {
        display: block;
      }
      img {
        width: 100%;
        height: clamp(7rem, 25vw, 9rem);
        border-radius: calc(var(--borderRadius) / 2);
        @include mat.elevation(2);
        object-fit: cover;
        @container (min-width: 30rem) {
          height: 7rem;
        }
      }
    }
    footer {
      display: flex;
      place-items: center;
      place-content: space-between;
      margin: 0 -0.7rem;
    }
  }
  lib-fullscreen:not(.fullscreen) lib-map > svg {
    max-height: 17rem;
  }
}
