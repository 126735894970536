.list {
  list-style: none;
  padding-left: 0;
  .list-item {
    margin-bottom: 10px;
  }
}

.mdc-list-item.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  .mdc-button {
    color: inherit;
  }
}
