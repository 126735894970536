.top-nav {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 1rem 0 1rem;
  background: var(--backgroundAlt);
  box-shadow: var(--dropShadow);
  border-bottom: 1px solid var(--subtle);
  z-index: 200;
  nav {
    max-width: var(--pageWidth);
    margin: 0 auto;
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-around;
    font-weight: bold;
    font-size: 1.2rem;
    width: 100%;
    .mat-mdc-tab-link {
      height: 52px;
      display: flex;
      color: currentColor;
      text-decoration: none;
      white-space: nowrap;
      position: relative;
      padding-left: 0;
      padding-right: 0;
      color: var(--mutedText);
      &.active {
        border-bottom: 2px solid var(--text);
        color: var(--text);
      }
    }
    .mat-mdc-tab-link .mdc-tab__text-label {
      color: inherit;
    }
  }
}
