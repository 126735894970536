@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;400;600&display=swap');
@import './variables';

:root {
  --pageWidth: 960px;
  --borderRadius: 1.1rem;
  --dialogWidth: #{$dialogWidth};
  --dialogHeight: #{$dialogHeight};
  color-scheme: light dark;
  supported-color-schemes: light dark;
}
:root,
:root.dark {
  // Default is dark theme
  @include darkTheme();
  @include darkContrast();
}
:root {
  // But user can also have light theme
  @media (prefers-color-scheme: light) {
    @include lightTheme();
    @include lightContrast();
  }
  &.light {
    @include lightTheme();
    @include lightContrast();
  }
  --selectedBackground: linear-gradient(145deg, var(--lightGreyHover) 0%, var(--panelHead) 100%);
  // --selectedBackground: var(--greyHover);
}

* {
  box-sizing: border-box;
}
html {
  font: normal 13px/20px $baseFont;
  font-size: clamp(0.75rem, 0.484rem + 1.25vw, 0.875rem);
  // letter-spacing: 0.25px;
}
html,
body {
  min-height: -webkit-fill-available;
  height: 100dvh;
}

body {
  background-color: var(--background);
  margin: 0;
  // margin: 1rem;
  overflow: hidden;

  color: var(--bodyText);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
b {
  margin: 0;
  color: var(--text);
}

h1,
.h1 {
  font: bold 3.4rem/4.4rem $baseFont;
  // letter-spacing: -0.01em;
}

h2,
.h2 {
  font: bold 2.4rem/3.14rem $baseFont;
  // letter-spacing: -0.01em;
}

h3,
.h3 {
  font: bold 1.7rem/2.2rem $baseFont;
}

h4,
.h4 {
  font: bold 1.2rem/1.7rem $baseFont;
  // letter-spacing: 0.15px;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
h5,
.h5 {
  font: bold 1.14rem/1.7rem $baseFont;
  // letter-spacing: 0.44px;
}

a {
  text-decoration: none;
  color: var(--accent);
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    body .mat-form-field .mat-input-element {
      font-size: 16px;
    }
  }
}

.flex {
  display: flex;
  place-content: space-between;
}

.login-form {
  form {
    --radius: var(--borderRadius);
    header {
      background: linear-gradient(0deg, #77e3c7, #98c6ff) !important;
    }
    .mdc-button {
      --buttonHeight: 3rem;
      box-shadow: none;
      .mdc-button__label {
        padding: 0 16px;
        box-shadow: none;
      }
    }
  }
}
