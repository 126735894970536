/* You can add global styles to this file, and also import other style files */
@import './styles/variables';
@import './styles/material';
@import './styles/elements';
@import './styles/nav';
@import './styles/buttons';
@import './styles/bookingCard';
@import './styles/icons';
@import './styles/list';
@import './styles/dialog';
@import './styles/overrides';
@import './styles/highcharts';
@import './styles/animations';
