@use '@angular/material' as mat;
@use '@angular/cdk' as cdk;
@import 'variables';

@include cdk.overlay();

body .cdk-overlay-container {
  pointer-events: none;
  // max-height: unset !important;
  .cdk-global-overlay-wrapper {
    pointer-events: none;
    .cdk-overlay-pane {
      pointer-events: none;
    }
  }
  .cdk-overlay-pane {
    width: auto;
  }
}
body .mat-mdc-dialog-container {
  max-width: var(--dialogWidth) !important;
  max-height: var(--dialogHeight) !important;
  height: auto;
  margin: auto;
  pointer-events: none;
  display: flex;
  .mdc-dialog__container {
    width: 100%;
    border-radius: var(--mdc-dialog-container-shape);
  }
  .mat-mdc-dialog-surface {
    padding: 1px 1.8rem;
    pointer-events: all;
  }
  .mat-mdc-dialog-title {
    place-items: center;
  }
  form {
    margin: 0;
  }
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  backdrop-filter: blur(3px);
}

.mat-mdc-dialog-title .mat-optgroup-label {
  display: none;
}

@media screen and (max-width: 600px) {
  .cdk-overlay-pane {
    // max-width: unset !important;
  }
}
@media screen and (max-width: 600px) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing.cdk-overlay-transparent-backdrop {
    // No backdrop should be transparent, as every backdrop is a popup
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .cdk-overlay-container {
    .cdk-overlay-connected-position-bounding-box {
      width: 100vw !important;
      height: 100vh !important;
      left: 0;
      right: 0 !important;
      bottom: 0 !important;
      top: 0;
    }
  }
  // Dropdown box
  .cdk-overlay-pane {
    // Reset dropdown inline styling
    left: unset !important;
    top: unset !important;
    height: auto;
    width: auto;
    transform: unset !important;
    min-width: 50vw;

    position: static;
    display: flex;
    pointer-events: auto;
    z-index: 1000;
    margin: auto;
    .mat-mdc-select-panel-wrap {
      max-width: var(--dialogWidth) !important;
      max-height: var(--dialogHeight) !important;
      height: auto !important;
      margin: auto;
      border-radius: 16px !important;
      overflow: hidden;
      @include mat.elevation(8);

      position: relative;
      text-align: center;
      display: grid;
      align-content: space-evenly;
    }
    .mat-mdc-select-panel {
      max-height: var(--dialogHeight);
      padding-bottom: 1rem;
    }
    .mat-mdc-dialog-title .mat-optgroup-label {
      color: var(--text);
      display: grid;
      height: 6rem;
      place-content: center;
      font-weight: bold;
      font-size: 1.2rem;
    }
  }
}

.mat-snack-bar-container.error {
  background-color: #cd4f4f;
}

.search-dialog {
  &.quick-booking {
    display: none;
  }
  .mat-mdc-dialog-container {
    height: 100%;
    padding-top: 0;
    @media screen and (max-width: 749px) {
      min-width: var(--dialogWidth);
    }
    @media screen and (min-width: 750px) {
      min-width: 600px;
    }
    &:has(flx-map-search),
    &:has(flx-show-colleague-dialog) {
      width: var(--dialogWidth);
    }
  }
}

body .cdk-overlay-container .cdk-overlay-pane {
  .mat-mdc-select-panel[role='listbox'] {
    border-radius: var(--borderRadius);
  }
}
