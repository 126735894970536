@use '@angular/material' as mat;
@import './variables';
@import './mixins';

@property --colorStop {
  syntax: '<color>';
  inherits: false;
  initial-value: transparent;
}
@property --colorStop2 {
  syntax: '<color>';
  inherits: false;
  initial-value: transparent;
}

// @mixin bGradient($name, $col1, $col2) {
//   &.#{$name} .booked {
//     --colorStop: #{$col1};
//     --colorStop2: #{$col2};
//   }
//   &.#{$name} .bPrefix {
//     --colorStop: #{$col1};
//     --colorStop2: #{$col2};
//   }
// }

.mdc-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button {
  color: inherit;
  background: transparent;
  .meta {
    pointer-events: all;
  }
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: var(--text);
  --mdc-icon-button-disabled-icon-color: var(--muted);
}
button,
label,
a {
  --buttonHeight: 3.64rem;
  --borderColor: var(--muted);
  --heavyBorderColor: hsl(from var(--text) h s l / 20%);
  --textColor: var(--bodyText);
  &.mdc-button {
    transition-duration: 0.1s;
    transition-timing-function: ease-in-out;
    transition-property: border, box-shadow;
  }
  .mdc-button__label {
    position: relative;
    .meta {
      position: absolute;
      top: 1px;
      bottom: 1px;
      right: 0;
      border-left: 1px solid var(--heavyBorderColor);
      box-shadow: -1px 0 0 var(--background);
      .mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: 100%;
        padding: 0 12px;
        display: flex;
        place-items: center;
        border-radius: 0;
      }
    }
  }
  &.outline {
    border: 2px solid var(--bodyText);
  }
  &[color='warning'] {
    --borderColor: var(--danger);
    --textColor: var(--danger);
  }
  &.mat-mdc-button-base {
    font-family: inherit;
  }
  &.mat-mdc-raised-button {
    background: var(--panelBack);
    &.mat-warn {
      --mdc-protected-button-container-color: var(--warning);
      --mdc-protected-button-label-text-color: var(--mdc-filled-button-label-text-color);
    }
    &.load-anim {
      --top: 2%;
      --bottom: 98%;
    }
    > .mdc-button__label {
      @include addPopout();
    }
  }
  &.mat-mdc-outlined-button {
    $border: 3px;
    --border-style: solid;
    &.empty {
      --border-style: dashed;
    }
    border: $border var(--border-style) var(--borderColor) !important;
    > .mdc-button__label {
      box-shadow: none;
    }
    color: var(--textColor);
    background: transparent;
    .bPrefix {
      border-right: $border var(--border-style) var(--borderColor);
      .flx-icon svg {
        filter: none;
      }
    }
    &:active,
    &:focus {
      --border-style: solid;
    }
  }
  &.small {
    --buttonHeight: 2rem;
    --borderRadius: 10px;
    .bPrefix {
      margin-right: 0;
      padding: 5px;
      + .bLabel {
        overflow: unset;
      }
    }
    .bLabel {
      padding: 8px 16px;
    }
    .flx-icon {
      max-width: 1.3rem;
      max-height: 1.3rem;
    }
  }
  &.large {
    --buttonHeight: 4.57rem;
  }

  &.mat-mdc-button-base.mat-mdc-mini-fab,
  &.mat-mdc-button-base.mat-mdc-fab {
    > .mdc-button__label:not(:empty) {
      padding: 0;
      width: 100%;
      height: var(--buttonHeight, 100%);
      line-height: var(--buttonHeight, 100%);
      display: flex;
      place-content: center;
    }
    &.filled {
      background-color: transparent;
    }
    .bPrefix {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      color: var(--textColor);
      display: flex;
      place-items: center;
      place-content: center;
    }
  }

  &.mat-mdc-button-base.mat-mdc-raised-button,
  &.mat-mdc-button-base.mat-mdc-outlined-button {
    border-radius: var(--borderRadius);
    overflow: hidden;
    height: var(--buttonHeight);
    width: 100%;
    min-width: 0;
    padding: 0;
    font-size: inherit;
    letter-spacing: 0.15px;
    line-height: inherit;
    > .mdc-button__label:not(:empty) {
      transition: box-shadow 100ms ease-in-out;
      display: flex;
      height: 100%;
      width: 100%;
      place-content: center;
      place-items: center;
    }
    .bPrefix {
      display: grid;
      height: 100%;
      width: clamp(3.5rem, 20%, 3.5rem);
      @supports not (width: clamp) {
        min-width: 3.5rem;
      }
      place-content: center;
      + .bLabel {
        text-align: left;
      }
    }
    .bLabel {
      width: 100%;
      padding: 0 1rem;
      flex: 1;
      place-self: center;
      overflow: hidden;
      text-overflow: ellipsis;
      // height: 100%;
      // place-content: center;
    }
    &.rounded {
      --borderRadius: 100px;
    }
    &.mdc-button-disabled,
    &[disabled] {
      color: var(--mutedText) !important;
      background-color: transparent !important; // var(--panelBack);
      box-shadow: 0 0 1px 2px var(--panelBack) inset !important;
      .mdc-button__label {
        box-shadow: none;
      }
      &:hover .mdc-button__label .bPrefix,
      .mdc-button__label .bPrefix {
        --colorStop: var(--panelHead) !important;
        --colorStop2: var(--muted) !important;
      }
      .bPrefix {
        filter: grayscale(1);
      }
    }
    .flx-icon {
      max-width: 1.8rem;
      max-height: 1.8rem;
      display: inline-block;
    }
  }
  &.mat-mdc-button-base {
    &.filled {
      // Apply gradients
      @include addAllGradientsAsFill('.bPrefix');
    }
  }
  &:not(.static):active,
  &:not(.static):focus {
    outline: none;
    > .mdc-button__label {
      box-shadow: none;
    }
    .bPrefix .flx-icon svg {
      filter: none;
    }
  }
}
.mat-mdc-outlined-button,
.mat-mdc-raised-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  color: var(--text);
  background-color: var(--panelBack);
}
.mat-mdc-unelevated-button {
  &:not(:disabled) {
    --mdc-filled-button-label-text-color: var(--text);
    --mdc-filled-button-container-color: transparent;
  }
  &[disabled][disabled] {
    --mdc-filled-button-disabled-container-color: transparent;
    --mdc-filled-button-disabled-label-text-color: var(--mutedText);
  }
}

.mat-mdc-button-base.mat-unthemed {
  --mdc-text-button-label-text-color: var(--text);
  &.mat-mdc-raised-button {
    --mdc-protected-button-container-color: var(--panelBack);
    --mdc-protected-button-label-text-color: var(--text);
  }
  &.mat-mdc-outlined-button {
    --mdc-outlined-button-label-text-color: var(--text);
    --mdc-outlined-button-outline-color: var(--borderColor);
  }
}
.mat-mdc-raised-button {
  &[disabled][disabled] {
    .mdc-button__label {
      box-shadow: none;
    }
    --mdc-protected-button-disabled-container-color: transparent;
    --mdc-protected-button-disabled-label-text-color: var(--mutedText);
  }
}
