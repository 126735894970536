// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;

@import url('https://fonts.googleapis.com/css2?family=Cantarell:wght@400;700&family=Roboto:wght@300;400;500&family=Fredoka+One&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

$light-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      use-system-variables: true,
    system-variables-prefix: sys,
    ),
    typography: (
      use-system-variables: true,
    system-variables-prefix: sys,
    ),
  )
);
$dark-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      use-system-variables: true,
    system-variables-prefix: sys,
    ),
    typography: (
      use-system-variables: true,
    system-variables-prefix: sys,
    ),
  )
);
