@mixin darkTheme() {
  --background: #2e2e33;
  --backgroundAlt: var(--background);
  --text: #ffffff;
  --bodyText: #ffffffbb;
  --textNoAlpha: #c7c7c9;
  --mutedText: #ffffff8f;
  --darkerText: #000000;
  --darkText: #0000008c;
  --lessSubtle: #00000066;
  --subtle: #ffffff17;
  --light: rgba(255, 255, 255, 0.03);
  --panelBack: #393940;
  --panelHead: #45454c;
  --lightGreyHover: hsl(240, 5%, 47%);
  --greyHover: var(--muted);
  --primary: hsl(182, 56%, 69%);
  --primaryText: hsl(182, 92%, 5%);
  --accent: hsl(193, 40%, 46%);
  --accentText: hsl(193, 40%, 76%);
  --muted: #58585e75;
  --selected: var(--muted);
  --success: #1dd1a1;
  --warning: #feca57;
  --danger: #f55238;
  --dangerText: #ff7e69;
  --dangerBack: #f55238;
  --optional: var(--warning);
  --notAvailable: hsl(8deg 40% 54% / 45%);
  --available: hsl(164deg 51% 46% / 89%);
  --hoursAvailable: var(--bodyText);
  --hoursUnavailable: var(--muted);

  --mapPrimary: var(--accent);
  --mapText: var(--text);
  --mapBack: hsl(193, 13%, 20%);
  --mapDark: hsl(193, 10%, 31%);
  --mapLight: hsl(193, 23%, 22%);
  --mapFilled: hsl(193deg 6% 30% / 47%);
  --mapShadow: #000000;
  --mapOutline: var(--mapPrimary);
  --disableGradientColor: rgb(255 255 255 / 30%);
  --dropShadow: 0 0 25px rgb(0 0 0 / 30%);

  --notActive: transparent;
  --cool: var(--notActive);
  --highlight: rgba(255, 255, 255, 0.1);
  --noHighlight: transparent;
}
@mixin lightTheme() {
  --background: #eceff1;
  --backgroundAlt: var(--background); // linear-gradient(45deg, #77e3c7, #98c6ff);
  --text: #45454c;
  --bodyText: #303035bb;
  --textNoAlpha: #626367;
  --mutedText: #45454c8f;
  --darkerText: #000000;
  --darkText: #0000008c;
  --lessSubtle: #ffffff77;
  --subtle: #00000033;
  --light: rgba(0, 0, 0, 0.03);
  --panelBack: #d8d8da;
  --panelHead: #a9a9b1a1;
  --lightGreyHover: hsl(240, 5%, 86%);
  --greyHover: var(--panelHead);
  --primary: hsl(182, 56%, 69%);
  --primaryText: hsl(182, 92%, 5%);
  --accent: hsl(193, 40%, 46%);
  --accentText: hsl(193, 40%, 36%);
  --muted: #58585e75;
  --selected: var(--panelHead);
  --success: #1dd1a1;
  --warning: #feca57;
  --danger: #f55238;
  --dangerText: #a63826;
  --dangerBack: hsl(8, 100%, 87%);
  --optional: var(--warning);
  --notAvailable: hsl(8deg 100% 85% / 45%);
  --available: hsl(164deg 71% 63% / 89%);
  --hoursAvailable: #ffffff;
  --hoursUnavailable: var(--muted);

  --mapPrimary: var(--accent);
  --mapText: var(--text);
  --mapBack: hsl(0, 0%, 74%);
  --mapDark: hsl(193, 10%, 60%);
  --mapLight: hsl(193, 23%, 73%);
  --mapFilled: hsl(193deg 6% 80%);
  --mapOutline: var(--mapPrimary);
  --mapShadow: rgb(108, 123, 127);
  --mapText: var(--text);
  --disableGradientColor: rgb(0 0 0 / 30%);
  --dropShadow: 0 0 25px rgb(0 0 0 / 18%);

  --notActive: transparent;
  --cool: var(--notActive);
  --highlight: white;
  --noHighlight: rgba(0, 0, 0, 0.1);
}

@mixin darkContrast() {
  &.contrast {
    --mutedText: #d7e6fbd4;
    --muted: #58585ef6;
  }
}

@mixin lightContrast() {
  &.contrast {
    --text: #151518;
    --bodyText: #151518bb;
    --mutedText: #3b3b44e0;
  }
}

// prettier-ignore
$gradients:
  (#fedf9a, #ff6b6b), // 1. Workplace
  (#98c6ff, #54a0ff), // 2. Home/Flex
  (#ff9ff3, #5f27cd), // 3. Co-Working
  (#ffa6a6, #ff9ff3), // 4.

  (#FF6B6B, #F368E0), // 5. Unavailable
  (#ff6b6b, hsl(0, 80%, 61%)), // 6. locker locked
  (#77e3c7, hsl(130, 79%, 35%)), // 7. locker open
  (#9AFED4, #21A48C), // 8. additional services
  (#77e3c7, #feca57),
  (#9f7de1, #ff6b6b),
  (#77e3c7, #98c6ff),
  (#FEBE9A, #FFDE6B); // 12. Autobooking

$baseFont: 'IBM Plex Sans', sans-serif;

$dialogWidth: 80vw;
$dialogHeight: 80vh;
